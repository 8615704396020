import * as React from "react"
import { BsInstagram } from "react-icons/bs"
import { AiOutlineLinkedin } from "react-icons/ai"
import { ImHeart } from "react-icons/im"

import "../styles/components/footer.scss"
import "../styles/hamburger.css"
import { graphql, useStaticQuery } from "gatsby"

const Footer = () => {
  const socials = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          socials {
            ig
            linkedin
          }
        }
      }
    }
  `).site.siteMetadata.socials

  return (
    <footer className="w-100 p-10 bg-primary-100 hidden lg:block">
      <div className="owner flex flex-row uppercase justify-center font-playfair font-bold items-center p-5 pt-0">
        <div className="socials flex flex-row relative font-black text-primary-700">
          <a
            href={socials.ig}
            className="text-inherit"
            target="_blank"
            rel="noreferrer"
          >
            <BsInstagram className="text-sm hover:text-pop duration-300 cursor-pointer" />
          </a>
          <a
            href={socials.linkedin}
            className="text-inherit"
            target="_blank"
            rel="noreferrer"
          >
            <AiOutlineLinkedin className="text-lg -top-[2px] relative ml-6 hover:text-pop duration-300 cursor-pointer" />
          </a>
        </div>
      </div>
      <div className="developer relative font-russolo p-5 pb-0 before:bg-primary-400">
        <div className="text-sm text-center text-primary-800 font-black">
          © {new Date().getFullYear()} <span className="ml-1">&middot;</span>
          <span className="ml-1">
          built with <ImHeart className="text-xs relative -top-[1px] inline-block"/> by myself
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
