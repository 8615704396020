import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import { BsInstagram } from "react-icons/bs"
import { MdWorkOutline } from "react-icons/md"
import {
  AiOutlineLinkedin,
  AiOutlineYoutube,
  AiOutlineHome,
  AiOutlineUser,
} from "react-icons/ai"
import { ImHeart } from "react-icons/im"

import "../styles/components/header.scss"
import "../styles/hamburger.css"

const Header = ({
  className,
  currentColor,
  navColor,
  hoverColor,
  popBG = false,
  showOnDispatchedEvent,
  shouldBeInactive = undefined
}) => {
  const socials = useStaticQuery(graphql`
    query SocialsQuery {
      site {
        siteMetadata {
          socials {
            ig
            linkedin
          }
        }
      }
    }
  `).site.siteMetadata.socials

  const [menuClickCount, setMenuClickCount] = React.useState(0)
  const [exiting, setExiting] = React.useState(false)
  const [path, setPath] = React.useState("")
  const [display, setDisplay] = React.useState(true)

  React.useEffect(() => {
    const url = typeof window !== "undefined" ? window.location.href : ""

    if (url !== "") {
      setPath(new URL(url).pathname.replace(/\/+$/, ""))
    }

  }, [showOnDispatchedEvent])

  let menuToggled = menuClickCount % 2 === 1
  let firstClick = menuClickCount > 0

  return (
    <>
      <header
        className={
          `${menuToggled ? `toggled` : ``} ${!firstClick ? `fresh` : ``}` +
          ` ${className ? className : ``}` +
          ` h-screen-fill-content w-20 p-6 flex justify-center` +
          ` z-[100000] lg:z-[100]`
        }
      >
        <div className="lg:hidden flex items-start">
          <div className="hamburger-container flex">
            <div
              className={
                `hamburger hamburger--slider ` +
                `${menuToggled ? `is-active` : ``} ` +
                `${exiting ? `exiting` : ``} `
              }
            >
              <button className="hamburger" type="button">
                <span
                  className="hamburger-box h-5"
                  onClick={() => {
                    setMenuClickCount(prevVal => {
                      if (prevVal === true) {
                        setExiting(true)
                        setTimeout(() => setExiting(false), 5150)
                      }
                      return prevVal + 1
                    })
                  }}
                >
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div
          className={`menu hidden sm-menu-icons-container lg:flex flex-col justify-center items-center ${
            navColor ? navColor : `text-primary-700`
          } h-100`}
        >
          <div className={`absolute h-full w-full ${shouldBeInactive ? `z-[100000] cursor-default` : `-z-[100]`}`}></div>
          <div className="option h-fit ">
            {path !== "" ? (
              <Link
                to="/"
                className={
                  `sm:text-xl lg:text-2xl no-underline text-inherit sm-menu-icon show ` +
                  `hover:${
                    hoverColor
                      ? hoverColor
                      : popBG
                      ? `text-primary`
                      : `text-pop`
                  } ` 
                }
              >
                <AiOutlineHome />
              </Link>
            ) : (
              <span
                className={
                  `sm:text-xl lg:text-2xl no-underline cursor-default sm-menu-icon show ` +
                  `${
                    currentColor
                      ? currentColor
                      : popBG
                      ? `text-primary`
                      : `text-pop`
                  } `
                }
              >
                <AiOutlineHome />
              </span>
            )}
          </div>
          <div className="option my-12 h-fit">
            {path !== "/work" ? (
              <Link
                to="/work"
                className={
                  `sm:text-xl lg:text-2xl no-underline text-inherit sm-menu-icon show ` +
                  `hover:${
                    hoverColor
                      ? hoverColor
                      : popBG
                      ? `text-primary`
                      : `text-pop`
                  } ` 
                }
              >
                <MdWorkOutline />
              </Link>
            ) : (
              <span
                className={
                  `sm:text-xl lg:text-2xl no-underline sm-menu-icon show ` +
                  `${
                    currentColor ? currentColor : `text-pop`
                  } cursor-default ` 
                }
              >
                <MdWorkOutline />
              </span>
            )}
          </div>
          <div className="option h-fit">
            {path !== "/contact" ? (
              <Link
                to="/contact"
                className={
                  `font-emberly-black-narrow show sm:text-xl lg:text-2xl no-underline text-inherit sm-menu-icon ` +
                  `hover:${
                    hoverColor
                      ? hoverColor
                      : popBG
                      ? `text-primary`
                      : `text-pop`
                  } ` 
                }
              >
                <AiOutlineUser />
              </Link>
            ) : (
              <span
                className={
                  `font-emberly-black-narrow sm:text-xl lg:text-2xl no-underline sm-menu-icon show ` +
                  `${
                    currentColor ? currentColor : `text-pop`
                  } cursor-default `
                }
              >
                <AiOutlineUser />
              </span>
            )}
          </div>
        </div>
      </header>
      <div
        className={
          `overlay lg:height-0 lg:width-0 fixed h-screen-fill-content w-screen` +
          ` ${menuToggled ? ` toggled` : ``}` +
          ` ${!firstClick ? ` fresh` : ` stale`}`
        }
      >
        <div
          className={
            `h-screen-fill-content w-screen absolute bg-primary/60 filter-overlay ` +
            `${menuToggled ? `toggled ` : ``}` +
            `${!firstClick ? `fresh` : ``}`
          }
        ></div>
        <div className="h-screen-fill-content w-screen blur-overlay absolute"></div>
        <div className="relative w-100 h-screen-fill-content mobile-menu-container">
          <div className="menu absolute text-3xl xs:text-4xl uppercase font-emberly-black-narrow text-center text-primary-100">
            <div className="block">
              {path !== "" ? (
                <Link
                  to="/"
                  className="emb-2xl no-underline uppercase text-center text-primary-700"
                >
                  Home
                </Link>
              ) : (
                <span className="emb-2xl font-normal no-underline uppercase text-center text-pop">
                  Home
                </span>
              )}
            </div>
            <div className="block my-6 xs:my-10">
              {path !== "/work" ? (
                <Link
                  to="/work"
                  className="emb-2xl no-underline uppercase text-center text-primary-700"
                >
                  Work
                </Link>
              ) : (
                <span className="emb-2xl no-underline uppercase text-center text-pop">
                  Work
                </span>
              )}
            </div>
            <div className="block">
              {path !== "/contact" ? (
                <Link
                  to="/contact"
                  className="emb-2xl no-underline uppercase text-center text-primary-700"
                >
                  Contact
                </Link>
              ) : (
                <span className="emb-2xl no-underline uppercase text-center text-pop">
                  Contact
                </span>
              )}
            </div>
          </div>
          <div
            className={`footer absolute bottom-4 text-primary-900 flex flex-col items-center ${
              menuToggled ? `toggled` : ``
            }`}
          >
            <div className="socials flex flex-row mb-6">
              <span className="mr-5 text-sm">
                <a
                  href={socials.ig}
                  className="text-inherit"
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsInstagram />
                </a>
              </span>
              <span className="relative -top-[1px]">
                <a
                  href={socials.linkedin}
                  className="text-inherit"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AiOutlineLinkedin />
                </a>
              </span>
            </div>
            <div className="dev-credits russolo-sm text-xs text-primary-900">
              © {new Date().getFullYear()} <span className="ml-1">&middot;</span>
              <span className="ml-1">
              built with <ImHeart className="text-primary-900 text-xs relative -top-[1px] inline-block"/> by myself
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
