import { graphql } from "gatsby"
import * as React from "react"

import { ThemeContext } from "../contexts/Theme"

import bus from "../components/bus"

import Layout from "../components/Layout"
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import Seo from "../components/Seo"

import "../styles/components/index.scss"

const SMALL_TEXT_CLASSES_DYNAMIC = "text-[2.5rem] sm:text-[3rem] md:text-[3rem] lg:text-[4rem] xl:text-[5rem]"

const DISABLE_ANIMATION = true 

const removeBodyClass = className => document ? document.body.classList.remove(className) : ``

const HeroText = ({ data, popBG, className }) => {

  return (
    <div className={`${className}`}>
      <span className="small-text">
        I'm
      </span>
      <span className={`big-text emb-2xl ${popBG ? `text-primary` : `text-pop`}`}>
        Shashank Pradeep
      </span>
      <span className="small-text">
        I'm a
      </span>
      <span className={`big-text emb-2xl ${popBG ? `text-primary` : `text-pop`}`}>
        {data.title}
      </span>
      <span className="small-text">
        I'm based in
      </span>
      <span className={`emb-2xl big-text ${popBG ? `text-primary` : `text-pop`}`}>
        {`${data.locationCity}, ${data.locationCountry}`}
      </span>
    </div>
  )
}

const IndexPage = ({ data }) => {
  data = data.site.siteMetadata

  const [isInAboutSection, setIsInAboutSection] = React.useState(null)
  const [display, setDisplay] = React.useState(null)
  const [firstRender, setFirstRender] = React.useState(true)
  const [showAnimation,setShowAnimation] = React.useState(false)
  const [hasAnimationBeenShown, setHasAnimationBeenShown] = React.useState(false)

  const aboutRef = React.useRef(null)
  const homeRef = React.useRef(null)
  const dynamicAnimationEndRef = React.createRef(null)
  const dynamicAnimationEndRefMD = React.createRef(null)

  const { device } = React.useContext(ThemeContext)

  React.useLayoutEffect(() => {
    if (typeof window !== "undefined") {

      let animationState = null ;
      
      if (window.localStorage && window.localStorage.getItem !== null) {
        animationState = window.localStorage.getItem('indexAnimationState')
      }
      
  
      // if(animationState === null) {
      //   setShowAnimation(true)
      //   setDisplay(false)
      // }
    }
  },[])

  React.useEffect(() => {
    if (typeof window !== "undefined") {

      window.scrollTo(0,0)

      if (document && document.body) {
        if (!document.body.classList.contains("no-scroll")) {
          document.body.classList.add("no-scroll")
        }
      }

      if ((dynamicAnimationEndRef.current || dynamicAnimationEndRefMD.current)) {

        if(DISABLE_ANIMATION) {
          document.body.classList.remove("no-scroll")
                
          setTimeout(() => {
            setDisplay(true)
            bus.dispatch("sideBarDisplayTrigger")
          },200)
          bus.dispatch("dynamicAnimationFinished")
        } else {
          if (window.localStorage) {

            let animationState = window.localStorage.getItem('indexAnimationState')
            const currentTime = Math.floor(Date.now()/1000)
  
            const animationEndCB = () => {
              setDisplay(true)
              setShowAnimation(false)
              bus.dispatch("dynamicAnimationFinished")
              bus.dispatch("sideBarDisplayTrigger")
              document.body.classList.remove("no-scroll")
              
              if (window.localStorage && window.localStorage.getItem !== null) {
                window.localStorage.setItem('indexAnimationState', JSON.stringify({
                  performedAt: Math.floor(Date.now()/1000)
                }))
              }
            }
  
            if(animationState !== null) {
              animationState = JSON.parse(animationState) 
  
              if(currentTime - animationState.performedAt > 3600) {
                setShowAnimation(true)
                setDisplay(false)
                setHasAnimationBeenShown(true)
                if (dynamicAnimationEndRef.current) dynamicAnimationEndRef.current.addEventListener("animationend", animationEndCB)
                if (dynamicAnimationEndRefMD.current) dynamicAnimationEndRefMD.current.addEventListener("animationend", animationEndCB)
              } else {
                document.body.classList.remove("no-scroll")
                
                setTimeout(() => {
                  setDisplay(true)
                  bus.dispatch("sideBarDisplayTrigger")
                },200)
                bus.dispatch("dynamicAnimationFinished")
                removeBodyClass("no-scroll")
              }
            } else {
              setShowAnimation(true)
              setDisplay(false)
              setHasAnimationBeenShown(true)
              if (dynamicAnimationEndRef.current) dynamicAnimationEndRef.current.addEventListener("animationend", animationEndCB)
              if (dynamicAnimationEndRefMD.current) dynamicAnimationEndRefMD.current.addEventListener("animationend", animationEndCB)
            }
  
          } else {
            setShowAnimation(true)
            setDisplay(false)
            setHasAnimationBeenShown(true)
            if (dynamicAnimationEndRef.current) dynamicAnimationEndRef.current.addEventListener("animationend", animationEndCB)
            if (dynamicAnimationEndRefMD.current) dynamicAnimationEndRefMD.current.addEventListener("animationend", animationEndCB)
          }
        }

      } 

      if (aboutRef.current) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              setIsInAboutSection(true)
            }
          },
          {
            threshold: 0.95,
            rootMargin: "0px 0px 0px 0px",
          }
        )
        observer.observe(aboutRef.current)
      }

      if (homeRef.current) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              setIsInAboutSection(false)
            }
          },
          {
            threshold: 0.95,
            rootMargin: "0px 0px 0px 0px",
          }
        )
        observer.observe(homeRef.current)
      }
    }
  }, [])

  const title = data.title.split("")
  const location = `${data.locationCity}, ${data.locationCountry}`.split("")

  React.useEffect(() => {
    if(isInAboutSection === true) {
      setFirstRender(false)
    }
    
  },[isInAboutSection])

  return (
    <>
      <Seo description="I'm Shashank Pradeep, a Full Stack Developer based in Bangalore, India. I am proficient in working with Go, Javascript, Typescript and Python. Reach out to me if you want to talk about something exciting!" page="index"/>
      <Layout
        popBG={true}
        noNavBar={device === "sm" || device === "xs" ? true : false}
        noFooter={true}
      >
        <div className="relative w-full min-h-screen-fill-content page-container" id="index">
          <div
            className="z-[100] w-full min-h-screen-fill-content relative into scroll-smooth landing-page-container hero-container bg-primary"
            ref={homeRef}
          >
            <div className="absolute min-h-screen-fill-content w-full header-container static-animation-container">
              <Sidebar
                isInAboutSection={isInAboutSection}
                about={aboutRef.current}
                home={homeRef.current}
                showOnDispatchedEvent="dynamicAnimationFinished"
                firstRender={firstRender}
                shouldBeInactive={showAnimation}
              />
              <div className="text-container z-[100] min-h-screen-fill-content w-100">
                <div
                  className={
                    `static-landing-text-container text-primary-700 text-left sm:text-center ` +
                    `w-[90%] xs:w-[80%] sm:w-[90%] md:w-3/4 xl:w-[60%] uppercase ` +
                    `top-1/2 fixed break-words -translate-y-1/2 px-6 sm:px-0 ` +
                    `left-0 sm:-translate-x-1/2 sm:left-1/2 ` + 
                    `${
                      !isInAboutSection && display
                        ? firstRender ? `first-render appear` : `appear `
                        : !display
                        ? ``
                        : `disappear`
                    } ` + 
                    `${ hasAnimationBeenShown ? `animation-shown` : ``}`
                  }
                >
                  <HeroText data={data} className="hero"/>
                </div>
                <div
                  className={
                    `about-text text-left lg:text-justify text-pop ` +
                    `${isInAboutSection && display ? `appear` : firstRender ? `first-render` : `disappear`}`
                  }
                  dangerouslySetInnerHTML={{__html:data.pageData.home.about}}
                >
                </div>
              </div>
              <Header
                showOnDispatchedEvent="dynamicAnimationFinished"
                navColor="text-primary-700"
                currentColor="text-pop"
                className={`hidden sm:flex`}
                shouldBeInactive={showAnimation}
              />
            </div>
            <div className={
              `min-h-screen-fill-content relative dynamic-landing-animation-container hidden lg:block text-left bg-primary` + 
              `${!showAnimation ? ` hide` : ` show`}`
            }>
              <div
                className={
                  `landing-animation animation-container dynamic-landing-text-container ` +
                  `w-[100%] sm:w-[80%] xl:w-[60%] text-center px-6 sm:px-0 left-1/2 -translate-x-1/2 ` +
                  `text-primary-700 flex flex-col uppercase -translate-y-1/2 ` +
                  `top-1/2 fixed ${!showAnimation ? `paused` : ``}`
                }
              >
                <div className="hero-container grid grid-cols-[1fr] grid-row-[1fr]">
                  <div
                    className={
                      `text-3xl sm:text-[3rem] md:text-[4.5rem] lg:text-[6rem] grid-cols-[1fr] grid-row-[1fr] ` +
                      `lg:text-5xl big-text row-start-1 col-start-1 w-100 hidden md:grid large hero-welcome-text align-center ` +
                      `${!showAnimation ? `paused` : ``}`
                    }
                  >
                    <span
                      className={`welcome-text ${SMALL_TEXT_CLASSES_DYNAMIC} row-start-1 col-start-1`}
                    >
                      NamastÉ
                    </span>
                    <span
                      className={`first-text ${SMALL_TEXT_CLASSES_DYNAMIC} text-primary-700 row-start-1 col-start-1`}
                    >
                      I'm
                    </span>
                    <span
                      className={`second-text ${SMALL_TEXT_CLASSES_DYNAMIC} text-primary-700 row-start-1 col-start-1`}
                    >
                      I'm a
                    </span>
                    <span
                      className={`third-text ${SMALL_TEXT_CLASSES_DYNAMIC} text-primary-700 row-start-1 col-start-1`}
                    >
                      I'm based in
                    </span>
                  </div>
                  <div
                    className={
                      `text-pop big-text ` +
                      `row-start-1 col-start-1 w-100`
                    }
                    id="hero-name"
                    data-text={`Shashank Pradeep`}
                  >
                    <span>S</span>
                    <span>h</span>
                    <span>a</span>
                    <span>s</span>
                    <span>h</span>
                    <span>a</span>
                    <span>n</span>
                    <span>k</span>
                    <em className="block sm:hidden"></em>
                    <span className="ml-0 sm:ml-3 lg:ml-4 xl:ml-5">P</span>
                    <span>r</span>
                    <span>a</span>
                    <span>d</span>
                    <span>e</span>
                    <span>e</span>
                    <span>p</span>
                  </div>
                  <div
                    className={
                      `text-[2.5rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] xl:text-[6rem] ` +
                      `leading-[2.5rem] sm:leading-[3rem] md:leading-[4rem] lg:leading-[5rem] xl:leading-[6rem] ` +
                      `text-pop big-text row-start-1 col-start-1`
                    }
                    id="hero-title"
                    data-text={data.title}
                  >
                    {
                    title.map((char, index) => {
                      if (char === " ") {
                        if(index > 5) {
                          return (
                            <>
                              <em className="block sm:hidden"></em>
                              <span
                                className="ml-0 sm:ml-3 lg:ml-4 xl:ml-5"
                                key={`key_${index}`}
                              ></span>
                            </>
                          )
                        } else {
                          return(
                            <span
                              className="ml-2 sm:ml-3 lg:ml-4 xl:ml-5"
                              key={`key_${index}`}
                            ></span>
                          )
                        }
                      }
                      return <span key={`key_${index}`}>{char}</span>
                    })}
                  </div>
                  <div
                    className={
                      `text-[2.5rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] xl:text-[6rem] ` +
                      `leading-[2.5rem] sm:leading-[3rem] md:leading-[4rem] lg:leading-[5rem] xl:leading-[6rem] ` +
                      `text-pop big-text row-start-1 col-start-1`
                    }
                    id="hero-location"
                    data-id="md-visible"
                    data-text={`${data.locationCity}, ${data.locationCountry}`}
                  >
                    {location.map((char, index) => {
                      if (char === " ") {
                        return (
                          <>
                            <em className="block sm:hidden"></em>
                            <span
                              className="ml-0 sm:ml-3 lg:ml-4 xl:ml-5"
                              key={`key_${index}`}
                            ></span>
                          </>
                        )
                      }
                      if (location.length - 1 === index) {
                        return (
                          <span
                            key={`key_${index}`}
                            ref={dynamicAnimationEndRefMD}
                          >
                            {char}
                          </span>
                        )
                      } else {
                        return <span key={`key_${index}`}>{char}</span>
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
            {
              // This is the mobile dynamic landing animation-container 
            }
            <div className={
              `min-h-screen-fill-content relative dynamic-landing-animation-container block lg:hidden text-left bg-primary` + 
              `${!showAnimation ? ` hide` : ` show`}`
            }>
              <div
                className={
                  `landing-animation animation-container dynamic-landing-text-container ` +
                  `w-[100%] sm:w-[80%] xl:w-[60%] text-left md:text-center left-0 md:left-1/2 px-6 sm:px-0 translate-x-0 ` +
                  `text-primary-700 flex flex-col uppercase -translate-y-1/2 ` +
                  `top-1/2 fixed ${!showAnimation ? `paused` : ``} md:-translate-x-1/2`
                }
              >
                <div
                    className={
                      `text-3xl grid sm:text-[3rem] md:text-[4.5rem] lg:text-[6rem] ` +
                      `lg:text-5xl big-text w-full lg:hidden small hero-welcome-text mobile top-[-8px] md:top-[-15px] relative text-left md:text-center ` +
                      `${!showAnimation ? `paused` : ``} text-left`
                    }
                  >
                    <span
                      className={`welcome-text mobile text-center ${SMALL_TEXT_CLASSES_DYNAMIC} text-primary-700 text-center absolute bottom-[-195%] row-start-1 col-start-1`}
                    >
                      NamastÉ
                    </span>
                    <span
                      className={`first-text ${SMALL_TEXT_CLASSES_DYNAMIC} text-primary-700 row-start-1 col-start-1`}
                    >
                      I'm
                    </span>
                    <span
                      className={`second-text ${SMALL_TEXT_CLASSES_DYNAMIC} text-primary-700 row-start-1 col-start-1`}
                    >
                      I'm a
                    </span>
                    <span
                      className={`third-text ${SMALL_TEXT_CLASSES_DYNAMIC} text-primary-700 row-start-1 col-start-1`}
                    >
                      I'm based in
                    </span>
                </div>
                <div className="hero-container grid grid-cols-[1fr] grid-row-[1fr]">
                  <div
                    className={
                      `text-pop big-text ` +
                      `row-start-1 col-start-1 w-100`
                    }
                    id="hero-name"
                    data-text={`Shashank Pradeep`}
                  >
                    <span>S</span>
                    <span>h</span>
                    <span>a</span>
                    <span>s</span>
                    <span>h</span>
                    <span>a</span>
                    <span>n</span>
                    <span>k</span>
                    <em className="block sm:hidden"></em>
                    <span className="ml-0 sm:ml-3 lg:ml-4 xl:ml-5">P</span>
                    <span>r</span>
                    <span>a</span>
                    <span>d</span>
                    <span>e</span>
                    <span>e</span>
                    <span>p</span>
                  </div>
                  <div
                    className={
                      `text-[2.5rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] xl:text-[6rem] ` +
                      `leading-[2.5rem] sm:leading-[3rem] md:leading-[4rem] lg:leading-[5rem] xl:leading-[6rem] ` +
                      `text-pop big-text row-start-1 col-start-1`
                    }
                    id="hero-title"
                    data-text={data.title}
                  >
                    {
                    title.map((char, index) => {
                      if (char === " ") {
                        if(index > 5) {
                          return (
                            <>
                              <em className="block sm:hidden"></em>
                              <span
                                className="ml-0 sm:ml-3 lg:ml-4 xl:ml-5"
                                key={`key_${index}`}
                              ></span>
                            </>
                          )
                        } else {
                          return(
                            <span
                              className="ml-2 sm:ml-3 lg:ml-4 xl:ml-5"
                              key={`key_${index}`}
                            ></span>
                          )
                        }
                      }
                      return <span key={`key_${index}`}>{char}</span>
                    })}
                  </div>
                  <div
                    className={
                      `text-[2.5rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] xl:text-[6rem] ` +
                      `leading-[2.5rem] sm:leading-[3rem] md:leading-[4rem] lg:leading-[5rem] xl:leading-[6rem] ` +
                      `text-pop big-text row-start-1 col-start-1`
                    }
                    id="hero-location"
                    data-text={`${data.locationCity}, ${data.locationCountry}`}
                  >
                    {location.map((char, index) => {
                      if (char === " ") {
                        return (
                          <>
                            <em className="block sm:hidden"></em>
                            <span
                              className="ml-0 sm:ml-3 lg:ml-4 xl:ml-5"
                              key={`key_${index}`}
                            ></span>
                          </>
                        )
                      }
                      if (location.length - 1 === index) {
                        return (
                          <span
                            key={`key_${index}`}
                            ref={dynamicAnimationEndRef}
                          >
                            {char}
                          </span>
                        )
                      } else {
                        return <span key={`key_${index}`}>{char}</span>
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed w-full z-[95] min-h-screen-fill-content text-container left-0 top-0">
            <div
              className={
                `static-landing-text-container text-primary-700 text-left sm:text-center ` +
                `w-[90%] xs:w-[80%] sm:w-[90%] md:w-3/4 xl:w-[60%] uppercase ` +
                `top-1/2 absolute break-words -translate-y-1/2 px-6 sm:px-0 ` +
                `left-0 sm:-translate-x-1/2 sm:left-1/2 ` + 
                `${
                  !isInAboutSection && display
                    ? firstRender ? `first-render appear` : `appear `
                    : !display
                    ? ``
                    : `disappear`
                }`
                
              }
            >
              <HeroText popBG data={data} className="hero"/>
            </div>
            <div
              className={`about-text text-left lg:text-justify text-white ` +
              `${isInAboutSection && display ? `appear` : firstRender ? `first-render` : `disappear`}`
              }
              dangerouslySetInnerHTML={{__html:data.pageData.home.about}}
            >
            </div>
          </div>
          <Sidebar
            zIndex={95}
            bgPop={true}
            isInAboutSection={isInAboutSection}
            about={aboutRef.current}
            home={homeRef.current}
          />
          <div
            className="z-[90] w-100 relative min-h-screen-fill-content about flex flex-col md:flex-row md:justify-center md:items-center md:pt-0"
            ref={aboutRef}
          >
            <div className="bg-pop absolute w-full max-w-screen min-h-screen-fill-content"></div>
            <div className="w-full left-0 top-0 font-raleway text-lg text-justify md:text-lg font-light text-primary-100"></div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        pageData {
          home {
            about
          }
        }
        name
        title
        locationCity
        locationCountry
      }
    }
  }
`

export default IndexPage
