import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import scrollIntoView from "scroll-into-view-if-needed"
import easyScroll from 'easy-scroll';
import Bowser from "bowser"; 

import { ThemeContext } from "../contexts/Theme"

import bus from "./bus"

import { BsInstagram } from "react-icons/bs"
import { AiOutlineLinkedin } from "react-icons/ai"

import "../styles/hamburger.css"
import "../styles/components/sidebar.scss"

const navigate = node => {
  document.body.classList.remove("no-scroll")

  const bowser = Bowser.getParser(window.navigator.userAgent);
  const nodeToNavigateToIsHome = node.classList.contains("landing-page-container")

  const os = bowser.getOSName(true) 

  if(os === "ios") {
    if (nodeToNavigateToIsHome) {
      scrollIntoView(node, { behavior: "smooth" })
    } else {
      easyScroll({
        'scrollableDomEle': window,
        'direction': 'bottom',
        'duration': 750,
        'easingPreset': 'easeInOutQuart',
        'scrollAmount': node.offsetTop,
      })
    }
  } else {
    scrollIntoView(node, { behavior: "smooth" })
  }
  
}



const Header = ({
  zIndex,
  bgPop,
  isInAboutSection,
  about,
  home,
  showOnDispatchedEvent,
  className,
  firstRender,
  shouldBeInactive = undefined
}) => {
  const [display, setDisplay] = React.useState(false)
  const { device } = React.useContext(ThemeContext) 
  const homeNavLine = React.useRef(null) 

  let smallDevice = device === "sm" || device === "xs" || device === "md" || device === "lg"

  console.log(device)

  React.useEffect(() => {
    if (!showOnDispatchedEvent) {
      setDisplay(true)
    }

    bus.on("sideBarDisplayTrigger", () => {
      setDisplay(true)
    })

    return () => {
      setDisplay(true)
    }
  }, [])


  const socials = useStaticQuery(graphql`
    query SocialsQuery2 {
      site {
        siteMetadata {
          socials {
            ig
            linkedin
          }
        }
      }
    }
  `).site.siteMetadata.socials

  return (
    <>
      <div
        className={`h-screen-fill-content left-0 w-20 z-[${
          zIndex ? zIndex : `100`
        }] top-0 p-6 pb-3 lg:py-12 fixed flex sidebar ${
          className ? className : ``
        }`}
      >
        <div
          className={`menu h-100 w-full font-emberly-black text-xl flex justify-center flex-col flex-1 relative`}
        >
          <div className={`absolute h-[150%] w-full ${shouldBeInactive ? `z-[100000] cursor-default` : `-z-[100]`}`}></div>
          <div
            className={
              `pl-5 z-10 ${bgPop ? `bg-pop` : `bg-primary`} ` +
              `absolute top-0 origin-center text-[1.5rem] sm:text-xl md:text-2xl home-nav text ` +
              `${!display ? `hide` : !isInAboutSection ? `hide` : `show`} ` +
              `hidden lg:flex large`
            }
          >
            <span
              onClick={() => navigate(home)}
              className={
                `${
                  bgPop
                    ? `text-primary-700 hover:text-primary`
                    : `hover:text-pop text-primary-700`
                } ` +
                `no-underline duration-300 tracking-wide uppercase ` +
                `pr-2 ${bgPop ? `bg-pop ` : `bg-primary`}`
              }
            >
              Home
            </span>
          </div>
          <div
            className={
              `z-10 ${bgPop ? `bg-pop` : `bg-primary`} ` +
              `flex lg:hidden absolute -top-[5px] origin-center text-2xl home-nav text ` +
              `${!display ? `hide` : !isInAboutSection ? `hide` : `show`} ` +
              `small before:bg-primary-800 font-emberly-bold `
            }
          >
            <span
              onClick={() => navigate(home)}
              className={
                `no-underline duration-300 tracking-wide uppercase ` +
                `small pr-2 ${
                  bgPop
                    ? `bg-pop text-primary-500`
                    : `bg-primary text-primary-700`
                }`
              }
            >
              Home
            </span>
          </div>
          <div
            ref={homeNavLine}
            className={
              `flex-grow-[1] line relative -z-1 home-nav-line ` +
              `${bgPop ? `before:bg-primary-700` : `before:bg-primary-500`} ` +
              `${
                !smallDevice
                  ? !display
                    ? ``
                    : !isInAboutSection
                    ? `hide`
                    : `show`
                  : ``
              } ` +
              `${firstRender !== undefined ? firstRender ? `first-render` : `` : ``} ` +
              `${smallDevice ? `small` : `large`}`
            }
          ></div>
          <div className="hidden lg:flex socials flex-col justify-center items-center sm:text-xl lg:text-2xl py-6">
            <a href={socials.ig} target="_blank">
              <BsInstagram
                className={
                  `text-lg icon opacity-0 cursor-default ` +
                  `${
                    bgPop
                      ? `hover:text-primary text-primary-500`
                      : `hover:text-pop text-primary-700`
                  } ` +
                  `cursor-pointer ${!display ? `` : `show`}`
                }
              />
            </a>
            <a href={socials.linkedin} target="_blank">
              <AiOutlineLinkedin
                className={
                  `icon opacity-0 text-2xl mt-12 cursor-default ` +
                  `${
                    bgPop
                      ? `hover:text-primary text-primary-500`
                      : `hover:text-pop text-primary-700`
                  } ` +
                  `cursor-pointer ${!display ? `` : `show`}`
                }
              />
            </a>
          </div>
          <div
            className={
              `flex-grow-[1] line relative -z-1 ${
                bgPop ? `before:bg-primary-700` : `before:bg-primary-500`
              } ` +
              `about-nav-line ${
                !display
                  ? firstRender !== undefined ? firstRender ? `first-render` : `` : ``
                  : isInAboutSection
                  ? `hide`
                  : `show`
              } ` +
              `${smallDevice ? `small` : `large`}`
            }
          ></div>
          <div
            className={
              `absolute sm:z-inherit ${
                bgPop ? `bg-pop` : `bg-primary`
              } font-emberly-bold ` +
              `lg:hidden text-[1.5rem] sm:text-xl md:text-2xl bottom-0 opacity-0 about-nav before:bg-primary-500 text small ` +
              `${
                !display ? `first-render` : isInAboutSection ? `hide` : `show`
              }`
            }
          >
            <span
              onClick={() => navigate(about)}
              className={
                `${
                  bgPop
                    ? `text-primary-500 bg-pop`
                    : `text-primary-700 bg-primary`
                } ` + `pr-2 no-underline duration-300 tracking-wide uppercase ` 
              }
            >
              About
            </span>
          </div>
          <div
            className={
              `hidden lg:flex sm:bottom-0 absolute sm:z-inherit sm:pr-5 ${
                bgPop ? `bg-pop` : `bg-primary`
              } ` +
              `text-2xl bottom-0 about-nav large text ` +
              `${
                !display ? `first-render` : isInAboutSection ? `hide` : `show`
              }`
            }
          >
            <span
              onClick={() => navigate(about)}
              className={`${
                bgPop ? `hover:text-primary` : `hover:text-pop`
              } text-primary-700 no-underline duration-300 tracking-wide uppercase ` + `text-[1.5rem] sm:text-xl md:text-2xl`}
            >
              About
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
