const eventBus = {
  on(event, callback) {
    if (document) {
      document.addEventListener(event, e => callback(e.detail))
    }
  },
  dispatch(event, data) {
    if (document) {
      document.dispatchEvent(new CustomEvent(event, { detail: data }))
    }
  },
  remove(event, callback) {
    if (document) {
      document.removeEventListener(event, callback)
    }
  },
}

export default eventBus
