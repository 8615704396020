/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"

import Header from "./Header"
import Footer from "./Footer"

import "../styles/components/layout.css"

(() => {
  if(typeof window !== 'undefined' && typeof document !== 'undefined') {
    const innerHeight = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--p-100vh', `${innerHeight}px`);
    window.localStorage.setItem("p-vh",innerHeight)

    window.addEventListener('resize',() => {
      const innerHeight = window.innerHeight * 0.01
      window.localStorage.setItem("p-vh",innerHeight)
      document.documentElement.style.setProperty('--p-100vh', `${innerHeight}px`);
    })
  }
})()

const Layout = props => {
  return (
    <>
      <div className="layout my-0 mx-auto max-w-full relative bg-primary">
        <div className="potrait-overlay h-screen-fill-content w-full relative">
          <div className="fixed w-full h-full left-0 top-0 bg-primary"></div>
          <div className="flex-col fixed align-center justify-center -translate-x-1/2 left-1/2 -translate-y-1/2 top-1/2 flex items-center w-full p-6 text-center">
            <span className="flex emb-2xl font-emberly-extra-bold-condensed relative -left-[2px] text-pop uppercase">Rotate</span>
            <span className="flex russolo-lg text-primary-700">
              Rotate your device to potrait mode for a refined experience
            </span>
          </div>
        </div>
        <div className="body relative w-full h-full">
          {!props.noNavBar ? (
            <Header popBG={props.popBG ? true : false} />
          ) : (
            <Header
              className="flex lg:height-0 lg:width-0 lg:absolute"
              popBG={props.popBG ? true : false}
            />
          )}
          <main className="min-h-screen-fill-content">{props.children}</main>
          {!props.noFooter ? <Footer /> : <></>}
        </div>
      </div>
    </>
  )
}

export default Layout
