/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, title, children, page }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            name
            description
            author
          }
        }
      }
    `
  )

  const siteTitle = `${site.siteMetadata.name} | ${site.siteMetadata.title}`
  const tagTitle = title ? `${title} - ${siteTitle}` : siteTitle

  const jsonLD = require(`../data/pages/jsonld/${page || "index"}.json`)

  return (
    <Helmet>
      <title>{tagTitle}</title>

      <meta name="title" content={tagTitle}/>
      <meta name="description" content={description}/>

      <meta name="theme-color" media="(prefers-color-scheme: light)" content="#b9a1c1"/>
      <meta name="theme-color" media="(prefers-color-scheme: dark)"  content="#65496F"></meta>

      <meta property="og:type" content="website"/>
      <meta property="og:title" content={tagTitle}/>
      <meta property="og:description" content={description}/>
      <meta property="og:image" content={`${site.siteMetadata.siteUrl}/${page}.png`}/>
      <meta property="og:locale" content="en_GB"/>
      <meta property="og:site_name" content={site.siteMetadata.name}/>
      <meta property="og:url" content={`${site.siteMetadata.siteUrl}/${page}/`}/>

      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:title" content={tagTitle}/>
      <meta property="twitter:description" content={description}/>
      <meta property="twitter:image" content={`${site.siteMetadata.siteUrl}/${page}.png`}/>

      <script type="application/ld+json">
        {JSON.stringify(jsonLD)}
      </script>

      {children}
    </Helmet>
  )
}


export default Seo
